import React from "react"

import { Link } from "gatsby";

import CiMethodoStyle from "./ci-methodo.module.scss";

const CiLegalUs = (props) => {

    return <section className={ CiMethodoStyle.methodo }>
    <div className={ CiMethodoStyle.inner }>
    <h3>Legal notice & General Terms and Condition</h3>
    <div className={ CiMethodoStyle.content }>
        <span>Legal notice</span>
        <br/><br/>
        Last updated : March 8th, 2021
        <br/><br/>
        <strong>Company</strong><br/>
        iAdvize Société par actions simplifiée (simplified limited liability company)<br/>
        Authorised representative and CEO: Julien Hervouët<br/>
        Company Registration number, Nantes: 519 698 914<br/>
        VAT identification number: FR40519698914<br/>
        Declaration at the French National Commission for Information Technology and Civil Liberties: 1453092
        <br/><br/>
        <strong>Address</strong><br/>
        9 Rue Nina Simone<br/>
        Euronantes Gare, Le Berlingot bât. B<br/>
        44000 Nantes<br/>
        France
        <br/><br/>
        <strong>Contact</strong><br/>
        contact@iadvize.com<br/>
        +33 (0)8 05 69 61 31<br/>
        <br/><br/>
        <strong>Hosting</strong><br/>
        Netlify, Inc. - <a href="https://www.netlify.com/">Netlify</a><br/>
        2325 3rd Street, Suite 296, San Francisco, California 94107<br/>
        <br/><br/>
        Applicable law : The following Legal notice and General Terms and Conditions shall be governed by French laws and shall be interpreted in accordance with these laws. Any dispute between you and iAdvize shall be subject to the non-exclusive jurisdiction of the courts of Nantes.
        <br/><br/>
    </div>
    <hr></hr>
    <div className={ CiMethodoStyle.content }>
        <br/><br/>
        <span>General Terms and Conditions (“GTC”)</span>
        <br/><br/>
        Last updated : March 8th, 2021
        <br/><br/>
        By browsing the sites www.iadvize.com, www.ibbu.com, www.conversational-maturity-index.com or any other sites or mini-sites belonging to iAdvize (each of these sites is referred to as the “Site”), you accept and undertake to unconditionally comply with the GTC herein, which we request you to read carefully.
        By using this Site, you agree not to carry out technical operations likely to affect the integrity, security or availability of the Site, and in particular agree not to undertake a study of the source code or reverse engineering.
        <br/><br/>
        <strong>If you do not accept the General Terms and Conditions of use herein, you are not authorised to use this Site and should immediately stop using it.</strong><br/><br/>
        <strong>Use of the contents of the Site – Intellectual Property rights</strong><br/>
        This Site as well as all materials and information published on the Site, including, in particular, the articles, documents, audio and video clips, graphics, logos, icons, images or downloads that it contains (elements also referred to as “Contents”) are protected under the laws on copyrights, registered trademarks and other French and international laws, and belong to, or are controlled by, iAdvize, third-parties having duly authorized the use by iAdvize or the party designated as the supplier of the Contents.
No commercial use, reproduction, representation, use, adaptation, modification, incorporation, translation, marketing, in part or in whole, of these elements (including those that may be downloaded or copied) may be made without the prior written consent of iAdvize, with the exception of use for private use subject to different or even more restrictive provisions of the applicable law.
In addition to copyright, the violation of one of these industrial and/or intellectual property rights is an offence punishable by imprisonment and the payment of a fine.
        <br/><br/>
        <strong>Trademarks</strong><br/>
        All products constitute registered brand names or trademarks belonging to iAdvize. All other trademarks present on the Site and not belonging to iAdvize are the property of their respective owners.
        <br/><br/>
        <strong>Privacy</strong><br/>
        iAdvize takes your privacy seriously. To know how we can protect your privacy, please consult the section on <Link to="/en/rgpd">Privacy</Link>.
        <br/><br/>
        <strong>Disclaimers and Limitations of Liability</strong><br/>
        This website and all its Contents are provided “as-is” without any express or implied warranty whatsoever, including, in particular, any warranty of title or of merchantability, of fitness for a particular purpose or of non-infringement. You hereby accept to use the site at your own risk.
iAdvize shall, under no circumstances, be liable towards anyone for special, accidental, indirect or consequential damages, or for loss of profits or income arising from the use of the Contents presented or from reliance on these Contents, even if we had been informed of the possibility of such damages.
Every effort has been made to guarantee the accuracy of the Contents presented. However, iAdvize does not assume any responsibility for the accuracy of information presented in these Contents. Information on products may be changed without prior notice. Reference to products or services not covered by iAdvize is provided solely for information purposes and does not constitute an approval or a recommendation.
The above disclaimers and limitations of liability are applicable to the fullest extent permitted by law, whether in contract, statute, tort (including, without limitation, negligence) or otherwise.
        <br/><br/>
        <strong>Links to third-party sites</strong><br/>
        iAdvize shall not be liable for the contents, data, products and other information offered via sites connected by hyperlinks. In particular, iAdvize shall not be liable for the contents of sites whose data do not comply with the applicable laws and regulations.<br/><br/>
        <strong>Additional Terms and Conditions</strong><br/>
        If any portion of these GTC is invalid or unenforceable in any jurisdiction, then (i) in that jurisdiction it shall be re-construed to the maximum effect permitted by law in order to effect its intent as nearly as possible, and the remainder of these GTC shall remain in full force and effect, and (ii) in every other jurisdiction, all of these GTC shall remain in full force and effect.
        We may revise these GTC at any time at our sole discretion by posting such revised GTC on the page Legal notice & General Terms and Conditions. Such revisions shall be effective as to you upon posting, unless explicitly stated by us. It is your responsibility to be aware of any such revised GTC by checking this page. Your continued use of this Website following changes to these GTC constitutes your agreement to the revised GTC.
        <br></br>
        <br></br>
        These GTC shall be governed by French laws and shall be interpreted in accordance with these laws. Any dispute between you and iAdvize shall be subject to the non-exclusive jurisdiction of the courts of Nantes.”
    </div>
    </div>
</section>
  
}

export default CiLegalUs



