import React, { Component } from 'react'


import CiMenu from "../components/conversational-index/usa/ci-menu/ci-menu";
import CiFooterFr from "../components/conversational-index/usa/ci-footer/ci-footer"
import CiLegalUsa from "../components/conversational-index/usa/ci-legal/ci-methodo";


import SEO from "../components/seo";


class LegaPageUS extends Component {

    render(){

        return(
            <>
                <SEO title="Conversational Maturity Index: Evaluate your Company's Conversational Maturity." image="https://info.iadvize.com/hubfs/CI%20-%202021/Thumbnail%20-%20Conversational%20Index%20(1).png" lang="en" description="By completing the online version of this audit, consisting of 19 simple questions, you will get an objective overview of your conversational maturity in less than 10 minutes."/>
                
                <CiMenu/>

                <CiLegalUsa/>

                <CiFooterFr/>

            </>
        )
    }

}

export default LegaPageUS